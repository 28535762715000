import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  catchError,
  map,
  of,
  throwError,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Login } from '../models/login.model';
import { User, UserResponse } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isAuthenticatedSubject = new BehaviorSubject<User | null>(null);
  public isAuthenticated: Observable<User | null> =
    this.isAuthenticatedSubject.asObservable();
  private authSecretKey = 'Bearer Token';

  constructor(private router: Router, private http: HttpClient) {
    if (typeof window !== 'undefined') {
      this.isAuthenticatedSubject.next(
        localStorage.getItem(this.authSecretKey) as User | null
      );
    }
  }

  login(model: Login) {
    return this.http
      .post<UserResponse>(
        `${environment.apiUrl}/${environment.version}/users/login`,
        model
      )
      .pipe(
        map((user: UserResponse) => {
          if (user) {
            console.log(user);
            this.setUser(user.result);
          }
        }),
        catchError(this.getErrorHandler)
      );
  }

  refreshUser(jwt: string | null) {
    if (jwt === null) {
      this.isAuthenticatedSubject.next(null);
      return of(undefined);
    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + jwt);

    return this.http
      .get<UserResponse>(
        `${environment.apiUrl}/${environment.version}/users/refresh-token`,
        {
          headers,
        }
      )
      .pipe(
        map((user: UserResponse) => {
          if (user) {
            this.setUser(user.result);
          }
        }),
        catchError(this.getErrorHandler)
      );
  }

  getJWT() {
    const key = localStorage.getItem(this.authSecretKey);
    if (key) {
      const user: User = JSON.parse(key);
      return user.token;
    } else {
      return null;
    }
  }

  isAuthenticatedUser(): boolean {
    return this.isAuthenticatedSubject.value != null;
  }

  setUser(user: User) {
    localStorage.setItem(this.authSecretKey, JSON.stringify(user));
    this.isAuthenticatedSubject.next(user);
  }

  getUser() {
    var userJson = localStorage.getItem(this.authSecretKey);
    return JSON.parse(userJson!) as User;
  }

  logout(): void {
    localStorage.removeItem(this.authSecretKey);
    this.isAuthenticatedSubject.next(null);
    this.router.navigate(['/auth/login']); // Redirect to login page after logout
  }

  getErrorHandler(response: HttpErrorResponse) {
    let errorMessage = 'Er is een fout opgetreden. Probeer nogmaals.';

    if (!response.error) {
      return throwError(() => errorMessage);
    }

    let responseErrorMessage = response.error.Errors[0];

    switch (responseErrorMessage) {
      case 'USER_NOT_FOUND':
        errorMessage = 'Er is geen account met dit e-mailadres bij bekend.';
        break;
      case 'INVALID_CREDENTIALS':
        errorMessage = 'Gebruikersnaam en/of wachtwoord is onjuist.';
        break;
    }
    return throwError(() => errorMessage);
  }
}
